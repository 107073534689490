import React from 'react'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import GridContent from '../components/UI/grid-content'

import { colours } from '../components/UI/news/panel-colours'

const Portfolio = ({ data, location }) => {
  const { introduction, projects } =
    data.allFile.edges[0].node.childMarkdownRemark.frontmatter

  return (
    <Layout
      location={typeof window !== 'undefined' && location}
      crumbLabel="Our Portfolio"
    >
      <GatsbySeo
        title="Our Portfolio"
        description="Welcome to the realm of projects that Irbisio is actively exploring."
      />
      <GridContent
        id="introduction"
        layout="--center-4"
        mode={introduction.mode}
        background={introduction.image}
      >
        {introduction.section && <h4>{introduction.section}</h4>}
        <h1>{introduction.heading}</h1>
        <p
          dangerouslySetInnerHTML={{
            __html: introduction.body,
          }}
        />

        {projects.types.map((project, i) => {
          return (
            <div className={`article-preview ${colours[i % 4]}`} key={i}>
              <h2>{project.title}</h2>
              <p
                className="markdown"
                dangerouslySetInnerHTML={{
                  __html: project.body,
                }}
              />
            </div>
          )
        })}
        <h2
          dangerouslySetInnerHTML={{
            __html: projects.statement,
          }}
        />
      </GridContent>
    </Layout>
  )
}

export default Portfolio

export const query = graphql`
  query {
    allFile(filter: { name: { eq: "portfolio" } }) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              introduction {
                mode
                section
                image {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(
                      width: 800
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
                heading
                body
              }
              projects {
                statement
                mode
                section
                image {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(
                      width: 800
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
                types {
                  body
                  title
                }
              }
            }
          }
        }
      }
    }
  }
`
